/**
* Template Name: eBusiness - v2.1.1
* Template URL: https://bootstrapmade.com/ebusiness-bootstrap-corporate-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

@charset "UTF-8";
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
html, body {
  height: 100%;
}

.floatleft {
  float: left;
}

.floatright {
  float: right;
}

.alignleft {
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
}

.alignright {
  float: right;
  margin-left: 15px;
  margin-bottom: 15px;
}

.aligncenter {
  display: block;
  margin: 0 auto 15px;
}

a:focus {
  outline: 0px solid;
}

img {
  max-width: 100%;
  height: auto;
}

.fix {
  overflow: hidden;
}

p {
  margin: 0 0 15px;
  color: #444;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway', sans-serif;
  margin: 0 0 15px;
  color: #444;
  font-weight: 500;
}

h1 {
  font-size: 48px;
  line-height: 50px;
}

h2 {
  font-size: 38px;
  line-height: 40px;
}

h3 {
  font-size: 30px;
  line-height: 32px;
}

h4 {
  font-size: 24px;
  line-height: 26px;
}

h5 {
  font-size: 20px;
  line-height: 22px;
}

h6 {
  font-size: 16px;
  line-height: 20px;
}

a {
  text-decoration: none;
}

a:hover {
  color: #3EC1D5;
  text-decoration: none;
}

a:active, a:hover {
  outline: 0 none;
}

body {
  background: #fff none repeat scroll 0 0;
  color: #444;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  overflow-x: hidden;
  line-height: 22px;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

input, select, textarea, input[type="text"], input[type="date"], input[type="url"], input[type="email"], input[type="password"], input[type="tel"], button, button[type="submit"] {
  -moz-appearance: none;
  box-shadow: none !important;
}

::-moz-selection {
  background: #3EC1D5;
  text-shadow: none;
}

::selection {
  background: #3EC1D5;
  text-shadow: none;
}

.area-padding {
  padding: 70px 0px 80px;
}

.area-padding-2 {
  padding: 70px 0px 50px;
}

.padding-2 {
  padding-bottom: 90px;
}

.section-headline h2, .section-headline .h2 {
  display: inline-block;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 70px;
  position: relative;
  text-transform: capitalize;
}

.section-headline h2::after, .section-headline .h2::after {
  border: 1px solid #333;
  bottom: -20px;
  content: "";
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 40%;
}

.sec-head {
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0;
  padding: 0 0 10px;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

.navbar {
  background-color: rgba(255, 255, 0, 0.9);
}

#header .navbar-brand {
  font-size: 48px;
  margin: 0;
  padding: 0;
  line-height: 1;
}

/*--------------------------------------------------------------
# Nav
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu, .nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  float: left;
}

.nav-menu a {
  display: block;
  position: relative;
  color: #000;
  padding: 8px 15px 12px 15px;
  transition: 0.3s;
  font-size: 15px;
}

.nav-menu a:hover, .nav-menu .active > a, .nav-menu li:hover > a {
  color: #3ec1d5;
  text-decoration: none;
}

.nav-menu .drop-down ul {
  display: block;
  position: absolute;
  left: 0;
  top: calc(100% - 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: ease all 0.3s;
}

.nav-menu .drop-down:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-menu .drop-down li {
  min-width: 180px;
  position: relative;
}

.nav-menu .drop-down ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #165a64;
}

.nav-menu .drop-down ul a:hover, .nav-menu .drop-down ul .active > a, .nav-menu .drop-down ul li:hover > a {
  color: #3ec1d5;
}

.nav-menu .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down > a {
  padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
  content: "\eaa0";
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

@media (max-width: 1366px) {
  .nav-menu .drop-down .drop-down ul {
    left: -90%;
  }
  .nav-menu .drop-down .drop-down:hover > ul {
    left: -100%;
  }
  .nav-menu .drop-down .drop-down > a:after {
    content: "\ea9d";
  }
}

.nav-link, .nav-link:hover {
  color: #000;
}

/*--------------------------------------------------------------
# Slider
--------------------------------------------------------------*/
.slider-area {
  color: #fff;
  position: relative;
  text-align: center;
}

.slider-content {
  padding: 180px 60px;
}

.layer-1-2 {
  margin: 20px 0;
}

.layer-1-1 h2 {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
}

.layer-1-2 h1 {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
}

.layer-1-3 {
  margin: 50px 0 0;
}

.layer-1-3 a.ready-btn {
  border: 1px solid #fff;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  margin-top: 30px;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
  z-index: 222;
}

.layer-1-3 a.ready-btn:hover {
  color: #fff;
  background: #3EC1D5;
  border: 1px solid #3EC1D5;
  text-decoration: none;
}

.ready-btn.right-btn {
  background: #25D366;
  border: 1px solid #25D366 !important;
}

.ready-btn.right-btn:hover {
  color: #25D366 !important;
  background: #fff !important;
  border: 1px solid #fff !important;
}

/* Normal desktop :992px. */
@media (min-width: 1400px) and (max-width: 1920px) {
  .slider-content {
    padding: 340px 0;
  }
}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1169px) {
  .slider-content {
    padding: 144px 0;
  }
}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
  .slider-content {
    padding: 93px 0;
  }
  .layer-1-2 h1 {
    font-size: 22px;
    line-height: 24px;
  }
  .layer-1-3 a.ready-btn {
    font-size: 14px;
    margin-top: 20px;
    padding: 10px 20px;
  }
}

@media (max-width: 767px) {
  .nivo-directionNav {
    display: none;
  }
  .slider-content {
    padding: 46px 0px;
  }
  .layer-1-2 {
    margin: 10px 0;
  }
  .layer-1-1 h2 {
    font-size: 14px;
    line-height: 16px;
  }
  .layer-1-2 h1 {
    font-size: 14px;
    line-height: 16px;
  }
  .layer-1-3 a.ready-btn {
    font-size: 12px;
    margin-top: 10px;
    padding: 8px 10px;
  }
  .layer-1-3 {
    margin: 10px 0 0;
  }
  .nivo-controlNav {
    bottom: -8px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .slider-content {
    padding: 70px 0px;
  }
  .layer-1-1 h2, .layer-1-2 h1 {
    font-size: 24px;
    line-height: 30px;
  }
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about-area {
  background-color: #f9f9f9;
}

.single-well > a {
  display: block;
}

.single-well ul li {
  color: #444;
  display: block;
  padding: 5px 0;
}

.single-well ul li i {
  color: #3EC1D5;
  padding-right: 10px;
  font-size: 12px;
}

.single-well p {
  color: #444;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services-icon {
  color: #444;
  display: inline-block;
  font-size: 36px;
  line-height: 36px;
  margin-bottom: 20px;
}

.section-headline.services-head > h2 {
  margin-bottom: 25px;
}

.services-details {
  padding-top: 40px;
  transition: all 0.5s ease 0s;
}

.services-details:hover h4, .services-details:hover .services-icon {
  color: #3EC1D5;
}

.row.second-row {
  margin-top: 40px;
}

.section-head > h2 {
  color: #333;
}

.single-services > h4 {
  color: #444;
  font-size: 24px;
  font-weight: 500;
}

.single-services > p {
  color: #333;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Skills
--------------------------------------------------------------*/
.our-skill-area {
  position: relative;
}

.test-overly {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
}

.progress-h4 {
  color: #fff;
  font-weight: 500;
}

/*--------------------------------------------------------------
# Review
--------------------------------------------------------------*/
.reviews-area {
  /* background: url(../../assets/img/background/bg1.jpg); */
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;
  width: 100%;
  height: auto;
  position: relative;
}

.work-left-text {
  background: #3EC1D5 none repeat scroll 0 0;
}

.work-right-text {
  background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
}

.work-right-text h2 {
  color: #fff;
  text-transform: uppercase;
  font-size: 24px;
}

.work-right-text h5 {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 34px;
  text-transform: uppercase;
}

.work-right-text .sus-btn {
  margin-left: 0;
  margin-top: 20px;
}

.single-awesome-4 {
  display: block;
  float: left;
  overflow: hidden;
  width: 33.33%;
}

.single-awesome-4 .add-actions {
  padding: 10px 20px;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.pst-content {
  padding-left: 10px;
}

.project-menu {
  margin-bottom: 40px;
  text-align: center;
}

.project-menu li {
  display: inline-block;
}

.project-menu li a {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #444;
  border-radius: 20px;
  color: #444;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin: 0 4px;
  padding: 6px 15px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}

.project-menu li a.active, .project-menu li a:hover {
  border-color: #3EC1D5;
  background: #3EC1D5;
  color: #fff;
  text-decoration: none;
}

.single-awesome-portfolio {
  float: left;
  overflow: hidden;
  padding: 15px;
  width: 25%;
  position: relative;
}

.single-awesome-project {
  overflow: hidden;
  margin-bottom: 30px;
}

.first-item {
  margin-bottom: 30px;
}

.awesome-img {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.awesome-img > a {
  display: block;
  position: relative;
}

.single-awesome-project:hover .awesome-img > a::after {
  opacity: 1;
}

.single-awesome-project:hover .add-actions {
  opacity: 1;
  bottom: 0;
}

.awesome-img > a::after {
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: 0.4s;
}

.add-actions {
  background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
  bottom: 30px;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: 10px 15px;
  position: absolute;
  transition: all 0.4s ease 0s;
  width: 100%;
}

.project-dec {
  display: block;
  height: 100%;
  width: 100%;
}

.project-dec a {
  display: block;
  height: 100%;
  width: 100%;
}

.project-dec h4 {
  margin-bottom: 5px;
}

.project-dec h4:hover {
  color: #fff;
}

.project-dec h4 {
  color: #ddd;
  font-size: 24px;
  margin-top: -45px;
  padding-top: 50%;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 800;
}

.project-dec span {
  color: #ddd;
  font-size: 13px;
}

.project-action-btn {
  display: block;
  height: 100%;
  text-align: center;
  transition: all 1s ease 0s;
  width: 100%;
}

.project-action-btn li {
  display: block;
  height: 100%;
  width: 100%;
}

.project-action-btn li a {
  display: block;
  height: 100%;
  width: 100%;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing-area {
  background: rgba(0, 0, 0, 0.02) none repeat scroll 0 0;
}

.pri_table_list {
  border: 1px solid #ccc;
  text-align: center;
  transition: all 0.4s ease 0s;
  background: #fff;
}

.pri_table_list h3 span {
  font-size: 16px;
}

.pri_table_list ol li {
  border-bottom: 1px solid #ccc;
  color: #666;
  padding: 12px 15px;
  position: relative;
  text-align: left;
}

.pri_table_list li.check.cross::after {
  content: "\f00d";
  font-family: fontawesome;
  font-size: 14px;
  position: absolute;
  right: 50px;
  top: 12px;
  color: indianred;
}

.pri_table_list li.check::after {
  content: "\f00c";
  font-family: fontawesome;
  font-size: 14px;
  position: absolute;
  right: 50px;
  top: 12px;
  color: #3EC1D5;
}

.pri_table_list button {
  background: #444 none repeat scroll 0 0;
  border: 1px solid #444;
  color: #fff;
  margin-bottom: 25px;
  padding: 10px 35px;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
  border-radius: 30px;
}

.pri_table_list > h3 {
  color: #333;
  font-size: 24px;
  font-weight: 700;
  line-height: 25px;
  padding: 30px 0 20px;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
}

.pri_table_list ol {
  list-style: outside none none;
  margin: 0;
  padding: 0 0 25px;
}

.pri_table_list.active {
  transition: all 0.4s ease 0s;
  position: relative;
  overflow: hidden;
}

.saleon {
  background: #3EC1D5 none repeat scroll 0 0;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  left: -26px;
  padding: 2px 25px;
  position: absolute;
  text-transform: uppercase;
  top: 16px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
}

.pri_table_list > button:hover {
  background-color: #fff;
  border: 1px solid #333;
  color: #333;
}

.active > h3 {
  background: #f5f5f5 none repeat scroll 0 0;
  color: #333;
  transition: all 0.4s ease 0s;
}

.active > button {
  background: #3EC1D5 none repeat scroll 0 0;
  border: 1px solid #3EC1D5;
  color: #fff;
  transition: 0.4s;
}

.active > button:hover {
  background: #333 none repeat scroll 0 0;
  border: 1px solid #333;
  color: #fff;
  transition: 0.4s;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/

.testi-inner {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;
}

.testi-overly {
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.quate {
  border: 1px dotted #fff;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 24px;
  height: 70px;
  line-height: 70px;
  width: 70px;
}

.quate:hover {
  color: #fff;
}

.testi-img img {
  border: 1px dotted rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  height: 80px;
  margin: 0 auto;
  padding: 5px;
  transition: background 0.6s ease-out 0s;
  width: 80px !important;
}

.testi-text p {
  color: #fff;
  font-size: 16px;
  line-height: 1.5em;
  margin: 20px 0;
  letter-spacing: 1px;
}

.testi-text h6 {
  color: #fff;
  font-size: 20px;
}

.testimonial-carousel.owl-carousel.owl-theme .owl-controls .owl-dots div.owl-dot > span {
  background: #fff none repeat scroll 0 0;
  display: inline-block;
  height: 8px;
  width: 8px;
  transition: 0.4s;
  border-radius: 50%;
}

.testimonial-carousel.owl-carousel.owl-theme .owl-controls .owl-dots {
  bottom: -30px;
  display: block;
  left: 50%;
  margin-left: -20px;
  position: absolute;
}

.testimonial-content {
  margin-bottom: 15px;
}

.testimonial-carousel.owl-carousel.owl-theme .owl-controls .owl-dots div.owl-dot {
  display: inline-block;
  margin: 0 3px;
}

.testimonial-carousel.owl-carousel.owl-theme .owl-controls .owl-dots div.owl-dot.active span {
  background: #3EC1D5;
}

.testimonial-carousel .owl-nav,
.testimonial-carousel .owl-dots {
  margin-top: 5px;
  text-align: center;
}

.testimonial-carousel .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd !important;
}

.testimonial-carousel .owl-dot.active {
  background-color: #3ec1d5 !important;
}

.testi-text.text-center > h6 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.guest-rev {
  color: #ddd;
  font-size: 16px;
}

.guest-rev > a {
  color: #3EC1D5;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Quote
--------------------------------------------------------------*/
.suscribe-area {
  background: #075E54 none repeat scroll 0 0;
  padding: 30px 0;
}

.suscribe-text {
  display: block;
  padding: 10px 0;
}

.suscribe-text h3 {
  color: #fff;
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.sus-btn {
  background: #fff none repeat scroll 0 0;
  border: 2px solid #fff;
  color: #25D366;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  margin-left: 100px;
  padding: 10px 20px;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 30px;
}

.sus-btn:hover {
  background: #25D366 none repeat scroll 0 0;
  border: 2px solid #fff;
  color: #fff;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact-area {
  height: auto;
  width: 100%;
}

.contact-content {
  padding: 100px;
  background: #000 none repeat scroll 0 0;
}

.contact-content-right {
  padding: 100px;
}

.single-icon img {
  height: 24px;
}

.single-icon-rounded {
  border: 1px solid #444;
  display: inline-block;
  padding: 5px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.single-icon p {
  font-size: 16px;
  line-height: 30px;
}

.contact-icon {
  margin-bottom: 40px;
}

#google-map {
  height: 370px;
  /* margin-bottom: 20px; */
}

.php-email-form .validate {
  display: none;
  color: red;
  margin: 0;
  font-weight: 400;
  font-size: 13px;
}

.php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.php-email-form input, .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.php-email-form input::focus, .php-email-form textarea::focus {
  background-color: #3ec1d5;
}

.php-email-form input {
  padding: 20px 15px;
}

.php-email-form textarea {
  padding: 12px 15px;
}

.php-email-form button[type="submit"] {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 1px solid #ccc;
  color: #444;
  font-size: 16px;
  font-weight: 700;
  margin-top: 8px;
  padding: 12px 30px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  border-radius: 30px;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog-area {
  height: auto;
  width: 100%;
}

.blog-text h4 a {
  color: #444;
  text-decoration: none;
}

.blog-text h4 {
  color: #444;
  margin-bottom: 15px;
}

.blog-btn {
  border-bottom: 1px dotted #444;
  color: #444;
  text-decoration: none;
}

.blog-btn {
  border-bottom: 1px dotted #444;
  color: #444;
  display: inline-block;
  padding: 0 1px 5px 0;
  position: relative;
  text-decoration: none;
}

.blog-btn {
  position: relative;
}

.blog-btn::after {
  content: "\f178";
  font-family: fontawesome;
  position: absolute;
  right: -20px;
  top: 1px;
  transition: all 0.3s ease 0s;
}

.blog-btn:hover::after {
  right: -30px;
}

.blog-btn:hover {
  color: #333;
  text-decoration: none;
}

.blog_meta span.date_type i {
  margin-left: 5px;
}

.blog-meta span.comments-type {
  margin-left: 5px;
}

.blog-meta span i {
  padding-right: 10px;
}

.blog-content .blog-meta {
  border-bottom: 1px dotted #333;
}

.blog-meta {
  border-bottom: 1px dotted #fff;
  padding: 10px 0;
}

.comments-type > a, .date-type, .blog-meta span.comments-type {
  color: #333;
  letter-spacing: 1px;
  margin-right: 5px;
}

.blog-meta .comments-type i {
  padding-right: 0 !important;
}

.blog-content-right .comments-type > a, .blog-content-right .date-type, .blog-content-right .blog-meta span.comments-type, .blog-content-right .blog-text p {
  color: #fff;
  letter-spacing: 1px;
}

.single-blog .ready-btn {
  border: 1px solid #444;
  border-radius: 30px;
  color: #444;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
}

.single-blog .ready-btn:hover {
  border: 1px solid #3EC1D5;
  color: #fff;
}

/*--------------------------------------------------------------
# Blog page
--------------------------------------------------------------*/
.page-area {
  position: relative;
}

.blog-page .banner-box {
  margin-bottom: 40px;
}

.search-option input {
  border: medium none;
  padding: 6px 15px;
  width: 80%;
}

.search-option {
  border: 1px solid #ccc;
  height: 42px;
  margin-bottom: 30px;
}

.search-option button {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  font-size: 20px;
  padding: 8px 23px;
}

.search-option button:hover {
  color: #3ec1d5;
}

.left-blog h4 {
  border-bottom: 1px solid #ddd;
  color: #444;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 15px 10px;
  text-transform: uppercase;
}

.left-blog {
  background: #f9f9f9 none repeat scroll 0 0;
  margin-bottom: 30px;
  overflow: hidden;
  padding-bottom: 20px;
}

.left-blog li {
  border-bottom: 1px solid #ddd;
  display: block;
}

.left-blog ul li a {
  color: #444;
  display: block;
  font-size: 14px;
  padding: 10px;
  text-transform: capitalize;
}

.recent-single-post {
  border-bottom: 1px solid #ddd;
  display: block;
  overflow: hidden;
  padding: 15px 10px;
}

.ready-btn {
  border: 1px solid #fff;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  margin-top: 30px;
  padding: 12px 40px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
  z-index: 222;
}

.ready-btn:hover {
  color: #fff;
  background: #3EC1D5;
  border: 1px solid #3EC1D5;
  text-decoration: none;
}

.post-img {
  display: inline-block;
  float: left;
  padding: 0 5px;
  width: 35%;
}

.pst-content {
  display: inline-block;
  float: left;
  width: 65%;
}

.pst-content p a:hover, .left-blog ul li a:hover {
  color: #3EC1D5;
}

.blog-page .single-blog {
  margin-bottom: 40px;
}

.pst-content p a {
  color: #444;
  font-size: 15px;
}

.header-bottom h1, .header-bottom h2 {
  color: #fff;
}

.blog-tags {
  padding: 1px 0;
}

.left-blog li:last-child {
  border-bottom: 0;
}

.popular-tag.left-blog ul li a:hover {
  color: #fff;
}

.popular-tag.left-side-tags.left-blog ul {
  padding: 0 10px;
}

.blog-1 .banner-box {
  margin-bottom: 30px;
}

.left-tags .left-side-tags ul li {
  border-bottom: 0;
}

.left-tags .left-side-tags ul li a {
  padding: 3px 10px;
  width: auto;
}

.left-side-tags h4 {
  margin-bottom: 15px;
}

/*--------------------------------------------------------------
# Blog Details
--------------------------------------------------------------*/
.post-information h2 {
  color: #363636;
  font-size: 22px;
  text-transform: uppercase;
}

.post-information {
  padding: 20px 0;
}

.post-information .entry-meta span a {
  color: #444;
  display: inline-block;
  padding: 10px 0;
}

.entry-meta span a:hover {
  color: #3EC1D5;
}

.post-information .entry-meta {
  border-bottom: 1px solid #ccc;
  margin: 20px 0;
}

.post-information .entry-meta span i {
  padding: 0 10px;
}

.entry-content > p {
  color: #444;
}

.entry-meta > span {
  color: #444;
}

.entry-content blockquote {
  background: #fff none repeat scroll 0 0;
  border-left: 5px solid #3EC1D5;
  font-size: 17.5px;
  font-style: italic;
  margin: 0 0 20px 40px;
  padding: 22px 20px;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: #3EC1D5;
  border-color: #3EC1D5;
  color: #fff;
  cursor: default;
  z-index: 3;
}

.social-sharing {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ccc;
  display: block;
  margin: 30px 0;
}

.social-sharing > h3 {
  display: inline-block;
  font-size: 18px;
  margin: 0;
  padding: 20px 10px;
}

.sharing-icon {
  display: inline-block;
  float: right;
  padding: 13px 10px;
}

.sharing-icon a {
  border: 1px solid #444;
  color: #444;
  display: block;
  float: left;
  font-size: 18px;
  height: 34px;
  line-height: 30px;
  margin-left: 10px;
  text-align: center;
  width: 34px;
}

.sharing-icon a:hover {
  color: #3EC1D5;
  border: 1px solid #3EC1D5;
}

.single-blog .author-avatar {
  float: left;
  margin-right: 10px;
}

.single-blog .author-description h2 {
  font-size: 18px;
  margin: 0;
  padding: 0 0 5px;
}

.author-info {
  background: #fff none repeat scroll 0 0;
  float: left;
  margin: 30px 0;
  padding: 15px;
  width: 100%;
}

.single-post-comments {
  margin-bottom: 60px;
  max-width: 650px;
}

.comments-heading h3, h3.comment-reply-title {
  border-bottom: 1px solid #e8e8e9;
  color: #444;
  font-size: 18px;
  margin: 0 0 20px;
  padding: 0 0 5px;
  text-transform: uppercase;
}

.comments-list ul li {
  margin-bottom: 25px;
}

.comments-list-img {
  float: left;
  margin-right: 15px;
}

.comments-content-wrap {
  color: #42414f;
  font-size: 12px;
  line-height: 1;
  margin: 0 0 15px 80px;
  padding: 10px;
  position: relative;
}

.author-avatar {
  display: inline-block;
  float: left;
  width: 10%;
}

.author-description h2 {
  color: #777;
  font-size: 20px;
  text-transform: uppercase;
}

.author-description h2 a {
  color: #000;
}

.comments-content-wrap span b {
  margin-right: 5px;
}

span.post-time {
  margin-right: 5px;
}

.comments-content-wrap p {
  color: #909295;
  line-height: 18px;
  margin-bottom: 5px;
  margin-top: 15px;
}

li.threaded-comments {
  margin-left: 50px;
}

.comment-respond {
  margin-top: 60px;
}

span.email-notes {
  color: #42414f;
  display: block;
  font-size: 12px;
  margin-bottom: 10px;
}

.comment-respond p {
  color: #444;
  margin-bottom: 5px;
}

.comment-respond input[type=text], .comment-respond input[type=email] {
  border: 1px solid #e5e5e5;
  border-radius: 0;
  height: 32px;
  margin-bottom: 15px;
  padding: 0 0 0 10px;
  width: 100%;
}

.comment-respond textarea#message-box {
  border: 1px solid #e5e5e5;
  border-radius: 0;
  max-width: 100%;
  padding: 10px;
  height: 130px;
  width: 100%;
}

.comment-respond input[type="submit"] {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 1px solid #3ec1d5;
  border-radius: 20px;
  box-shadow: none;
  color: #444;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
  line-height: 14px;
  margin-top: 20px;
  padding: 10px 15px;
  text-shadow: none;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  white-space: nowrap;
}

.comments-content-wrap span a {
  color: #000;
}

.comments-content-wrap span a:hover {
  color: #3EC1D5;
}

.comment-respond input[type=submit]:hover {
  border: 1px solid #3EC1D5;
  color: #fff;
  background: #3EC1D5;
}

.single-blog .blog-pagination {
  border-top: 1px solid #e5e5e5;
  margin: 0;
  padding-top: 30px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer-area {
  padding: 40px 0;
  background: #f9f9f9;
}

.footer-head p {
  color: #444;
}

.footer-head h4 {
  color: #444;
  font-size: 16px;
  letter-spacing: 2px;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.footer-icons ul li {
  display: inline-block;
}

.footer-icons ul li a {
  border: 1px solid #444;
  color: #444;
  display: block;
  height: 40px;
  margin-right: 5px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-icons .footer-icon {
  height: 16px;
}

.flicker-img > a {
  float: left;
  padding: 1px;
  width: 33.33%;
}

.footer-icons {
  margin-top: 30px;
}

.footer-contacts p span {
  color: #3EC1D5;
  font-weight: 700;
}

.popular-tag ul li {
  display: inline-block;
}

.footer-content {
  display: block;
  overflow: hidden;
}

.popular-tag ul li a:hover, .footer-icons ul li a:hover {
  background: #3EC1D5;
  border: 1px solid #3EC1D5;
  color: #fff;
}

.popular-tag ul li a {
  border: 1px solid #444;
  border-radius: 30px;
  color: #444;
  display: block;
  font-size: 13px;
  font-weight: 600;
  margin: 5px 3px;
  padding: 5px 10px;
  position: relative;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 0.4s ease 0s;
  width: 70px;
  text-align: center;
}

.footer-area-bottom {
  background: #f1f1f1 none repeat scroll 0 0;
  padding: 15px 0;
}

.copyright-text a:hover {
  text-decoration: underline;
  color: #3EC1D5;
}

.copyright-text a {
  color: #444;
}

.copyright > p {
  margin-bottom: 0;
  color: #444;
}

.copyright a, .credits a {
  color: #3EC1D5;
}

.credits {
  padding-top: 5px;
  text-align: center;
}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
@media (min-width: 1920px) {
  .work-right-text {
    padding: 150px 150px;
  }
}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1169px) {
  .slider-content {
    padding: 146px 0;
  }
  .work-right-text {
    padding: 40px 0;
  }
  .work-right-text h2 {
    font-size: 18px;
    line-height: 28px;
  }
}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
  .layer-1-1 h2 {
    font-size: 24px;
  }
  .layer-1-2 h1 {
    font-size: 31px;
    line-height: 38px;
    padding: 0px 30px;
  }
  .tab-menu ul.nav li a {
    padding: 10px 16px;
  }
  .suscribe-input input {
    width: 60%;
  }
  .suscribe-input button {
    width: 40%;
  }
  .team-content.text-center > h4 {
    font-size: 20px;
  }
  .sus-btn {
    margin-left: 0;
  }
  .suscribe-text h3 {
    font-size: 16px;
    padding-right: 20px;
  }
  .work-right-text h5 {
    font-size: 14px;
    line-height: 22px;
  }
  .work-right-text {
    padding: 36px 0;
  }
  .work-right-text h2 {
    font-size: 14px;
    line-height: 22px;
  }
  .work-right-text .ready-btn {
    font-size: 13px;
    padding: 7px 20px;
    margin-top: 5px;
  }
  .single-awesome-portfolio {
    width: 33.33%;
  }
  .widget-product a img {
    display: block;
    float: none;
    width: 100%;
  }
  .widget-product .product-info {
    display: block;
    float: none;
    padding-left: 0;
    width: 100%;
    margin-top: 20px;
  }
  .map-column {
    margin-left: 0;
    padding-right: 40px;
  }
  .post-information .entry-meta {
    font-size: 13px;
    padding: 5px 0;
  }
  .post-information .entry-meta span a {
    padding: 4px 0;
  }
  .service-pic {
    margin-bottom: 30px;
    text-align: center;
  }
  .single-add-itms {
    width: 50%;
  }
  .left-sidebar-title > h4 {
    font-size: 18px;
  }
  .contact-form {
    margin-top: 0px;
  }
  .search-option input {
    width: 67%;
  }
}

/* small mobile :320px. */
@media (max-width: 767px) {
  .slider-area {
    margin-top: 60px;
  }
  .slider-content {
    padding: 80px 0;
  }
  .slider-content h2 {
    font-size: 18px !important;
    line-height: 24px !important;
  }
  .slider-content h1 {
    font-size: 20px !important;
    line-height: 26px !important;
  }
  .layer-1-3 a.ready-btn {
    padding: 8px 15px;
  }
  .section-headline h2, .section-headline .h2 {
    font-size: 30px;
  }
  .well-middle .single-well {
    margin-top: 30px;
  }
  .single-skill {
    margin-bottom: 40px;
  }
  .section-headline h3 {
    font-size: 25px;
  }
  .well-text > h2 {
    font-size: 18px;
  }
  .well-text p {
    display: none;
  }
  .sus-btn {
    margin-left: 0;
    margin-top: 30px;
  }
  .contact-form {
    margin-top: 30px;
  }
  .footer-content {
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .slider-content {
    padding: 0;
  }
}
